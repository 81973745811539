const loginsData = [
    {
        email: 'isuruariyarathna2k00@gmail.com',
        password: 'secretpassword124',
        role: 'super-admin'
    },
    {
        email: 'd.p.mannapperuma@gmail.com',
        password: 'NA0TR7Egj5wPKjl',
        role: 'super-admin'
    },
    {
        email: 'sachinthana@stemup.lk',
        password: '3yHIjlmdWtxPH06',
        role: 'super-admin'
    },
    {
        email: 'emperorisuruariyarathna@gmail.com',
        password: '200022601754',
        role: 'admin',
        centerId: ['0001', '0018']
    },
    {
        email: 'kavindu.ranasinghe07@gmail.com',
        password: '200103802080',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'wijesundaraishara2001@gmail.com',
        password: '200171703877',
        role: 'admin',
        centerId: ['0001']
    },
    {
        email: 'ypasanjith@gmail.com',
        password: '200104802122',
        role: 'admin',
        centerId: ['0001']
    },
    {
        email: 'umaireravur@gmail.com',
        password: '199424002341',
        role: 'admin',
        centerId: ['0016']
    },
    {
        email: 'arsathmaisoor@gmail.com',
        password: '199928700990',
        role: 'admin',
        centerId: ['0016']
    },
    {
        email: 'aasikcs@gmail.com',
        password: '199415810052',
        role: 'admin',
        centerId: ['0016']
    },
    {
        email: 'arunodayasachinthana@gmail.com',
        password: '200014802268',
        role: 'admin',
        centerId: ['0003', '0018']
    },
    {
        email: 'ojayweere@gmail.com',
        password: '200126201812',
        role: 'admin',
        centerId: ['0003', '0018']
    },
    {
        email: 'mandirabasnayaka222@gmail.com',
        password: '200165402615',
        role: 'admin',
        centerId: ['0003', '0018']
    },
    {
        email: 'majithdushantha@gmail.com',
        password: '200020400153',
        role: 'admin',
        centerId: ['0003', '0018']
    },
    {
        email: 'oshinidewdini@gmail.com',
        password: '200271702389',
        role: 'admin',
        centerId: ['0003', '0018']
    },
    {
        email: 'methmimaleesha09@gmail.com',
        password: '200181402673',
        role: 'admin',
        centerId: ['0003', '0018']
    },
    {
        email: 'wamasha2016@gmail.com',
        password: '200172703383',
        role: 'admin',
        centerId: ['0003', '0018']
    },
    {
        email: 'hasisewwandi690@gmail.com',
        password: '200276702855',
        role: 'admin',
        centerId: ['0003', '0018']
    },
    {
        email: 'wathsalabandara2001@gamil.com',
        password: '200166303530',
        role: 'admin',
        centerId: ['0003', '0018']
    },
    {
        email: 't.dasanthaedirisinghe@gmail.com',
        password: '200200303245',
        role: 'admin',
        centerId: ['0006']
    },
    {
        email: 'thipuni.wijesinghe@gmail.com',
        password: '200074002460',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'lakshanmanusha67@gmail.com',
        password: '200031702452',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'buddhimalsampath2001@gmail.com',
        password: '200118703664',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'ruvininwijerathna@gmail.com',
        password: '200173702977',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'sandaruwansanura@gmail.com',
        password: '200108303359',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'malshamarasinghe44@gmail.com',
        password: '200165202410',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'thishmiamaya@gmail.com',
        password: '200152802571',
        role: 'admin',
        centerId: ['0015']
    },
    {
        email: 'sachira.gavinda@gmail.com',
        password: '200012002001',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'pawanhansana13@gmail.com',
        password: '200123403197',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'pandithage.senuu31@gmail.com',
        password: '200174400484',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'annerashmitha611@gmail.com',
        password: '200166300832',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'jalendra12014@gmail.com ',
        password: '200172104018',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'pathumcmadhusanka281@gmail.com',
        password: '200103904207',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'supuledirisinghe@gmail.com',
        password: '981402464v',
        role: 'admin',
        centerId: ['0007', '0002', '0017']
    },
    {
        email: 'bhagyasewwandi302@gmail.com',
        password: '200065201733',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'nimansabasnayaka0220@gmail.com',
        password: '200174103924',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'amandirashini1112@gmail.com',
        password: '200181702343',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'S20534@sci.pdn.ac.lk',
        password: '200134803294',
        role: 'admin',
        centerId: ['0016', '0005']
    },
    {
        email: 'nawoda.wickramanayake@gmail.com',
        password: '200064000138',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'Damithrafdo@gmail.com',
        password: '993001791v',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'dilmianuththara670@gmail.com',
        password: '200081300090',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'sulakshanamadhuranga@gmail.com',
        password: '200130303116',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'aflalibnuhaja@gmail.com',
        password: '992165073V',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'isurikalpana009@gmail.com',
        password: '200175302990',
        role: 'admin',
        centerId: ['0015']
    },
    {
        email: 'vimanshamadushani88@gmail.com',
        password: '200153101172',
        role: 'admin',
        centerId: ['0007', '0002', '0017']
    },
    {
        email: 'smathiesha@gmail.com',
        password: '200103400476',
        role: 'admin',
        centerId: ['0007', '0002', '0017']
    },
    {
        email: 'kaushalyachamodi22@gmail.com',
        password: '200069800100',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'Yasiwije20@gmail.com',
        password: '200022200135',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'hansidananjana7@gmail.com',
        password: '200060203199',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'tdannjaya0@gmail.com',
        password: '991020101v',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'nimesh.pahasara@gmail.com',
        password: '200034800688',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'dhanushidds9@gmail.com',
        password: '995401991v',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'madumaliampitiya@gmail.com',
        password: '200184101313',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'yasela2014@gmail.com',
        password: '200003801654',
        role: 'admin',
        centerId: ['0007', '0002', '0017']
    },
    {
        email: 'madumaliampitiya@gmail.com',
        password: '200184101313',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'ravinduhimal00@gmail.com ',
        password: '200036000071',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'wijekoonrashmi@gmail.com',
        password: '200157400022',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'shehanirodrigo4gmail.com',
        password: '200067701640',
        role: 'admin',
        centerId: ['0011', '0013']
    },
    {
        email: 'chathuprabodha690@gmail.com',
        password: '200068701955',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'tharushadakshaka@gmail.com',
        password: '200011800571',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'nazeemthebeta@gmail.com',
        password: '980231844V',
        role: 'admin',
        centerId: ['0016']
    },
    {
        email: 'ab.muhaimin95@gmai.com',
        password: '951553069V ',
        role: 'admin',
        centerId: ['0016']
    },
    {
        email: 'imadhahmeth08@gmail.com',
        password: '200224103240',
        role: 'admin',
        centerId: ['0016']
    },
    {
        email: 'aflalibnuhaja@gmail.com ',
        password: '992165073V ',
        role: 'admin',
        centerId: ['0016']
    },
    {
        email: 'weerasinghe.dilina683@gmail.com',
        password: '200025800891',
        role: 'admin',
        centerId: ['0010']
    },
    {
        email: 'bkaviranga2000@gmail.com',
        password: '200028001537',
        role: 'admin',
        centerId: ['0011']
    },
    {
        email: 'annerashmitha611@gmail.com',
        password: '200166300832',
        role: 'admin',
        centerId: ['0011']
    },
    {
        email: 'lahirulakshithaprathapage@gmail.com',
        password: '200031602961',
        role: 'admin',
        centerId: ['0011']
    },
    {
        email: 'amandirashini1112@gmail.com',
        password: '200181702343',
        role: 'admin',
        centerId: ['0011']
    },
    {
        email: 'avanthakaweesh@gmail.com',
        password: '991931457v',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'tharindudilshanga@gmail.com',
        password: '992830646v',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'warnasooriyawpss19@gmail.com',
        password: '199976301978',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'sanuvithisansa00@gmail.com',
        password: '200085700278',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'gunarathneasalya95@gmail.com',
        password: '200053401332',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'ugdissanayake24@gmail.com',
        password: '200035900734',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'sakunatishanadesha@gmail.com',
        password: '200122900298',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'sashanrumesh2222@gmail.com',
        password: '200101600931',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'hansikarajakaruna20@gmail.com',
        password: '200070201823',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'nawoda.wickramanayake@gmail.com',
        password: '200064000138',
        role: 'admin',
        centerId: ['0013']
    },
    {
        email: 'chameerasandakelum69@gmail.com',
        password: '200120800277',
        role: 'admin',
        centerId: ['0014']
    },
    {
        email: 'samudradeshan@gmail.com',
        password: '200131003416',
        role: 'admin',
        centerId: ['0011']
    },
    {
        email: 'pawanhansana13@gmail.com',
        password: '200123403197',
        role: 'admin',
        centerId: ['0011']
    },
    {
        email: 'ruvininwijerathna@gmail.com',
        password: '200173702977',
        role: 'admin',
        centerId: ['0011']
    },
    {
        email: 'theekshanathennakoon79@gmail.com',
        password: '200031302175',
        role: 'admin',
        centerId: ['0011']
    },
    {
        email: 'kumudugamage2001@gmail.com',
        password: '200181103797',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'lasithmanodya0630@gmail.com',
        password: '200105903021',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'diniduthilakshan1@gmail.com',
        password: '200120104576',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'rukshan.bwd@gmail.com',
        password: '200120801559',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'ashcharyaalbert@gmail.com',
        password: '966261129V',
        role: 'admin',
        centerId: ['0006']
    },
    {
        email: 'hashensachintha@gmail.com',
        password: '200107101245',
        role: 'admin',
        centerId: ['0012']
    },
    {
        email: 'kushankapabodi@gmail.com',
        password: '200156502611',
        role: 'admin',
        centerId: ['0011']
    },
    {
        email: 'malshashalani95@gmail.con',
        password: '200075101987',
        role: 'admin',
        centerId: ['0011']
    },
    {
        email: 'jayathilake.nimanthi99@gmail.com',
        password: '995922088V',
        role: 'admin',
        centerId: ['0010']
    },
    {
        email: 'sachindunethminweerasinghe@gmail.com',
        password: '200109402239',
        role: 'admin',
        centerId: ['0015']
    },
    {
        email: 'vimanshamadushani88@gmail.com',
        password: '200153101172',
        role: 'admin',
        centerId: ['0007']
    },
    {
        email: 'smmdasuni@gmail.com',
        password: '200259702428',
        role: 'admin',
        centerId: ['0007']
    },
    {
        email: 'tharindukasthurisinghe@gmail.com',
        password: '200015202286',
        role: 'admin',
        centerId: ['0017']
    },
    {
        email: 'Indumawithanage1@gmail.com',
        password: '200235201941',
        role: 'admin',
        centerId: ['0015']
    },
    {
        email: 'kavindyaalwis83@gmail.com',
        password: '200071500380',
        role: 'admin',
        centerId: ['0007']
    },
    {
        email: 'dilinijayaweera43@gmail.com',
        password: '200180902714',
        role: 'admin',
        centerId: ['0007']
    },
    {
        email: 'sanuwic@gmail.com',
        password: 'AKrnPtqKJGGE9kM',
        role: 'admin',
        centerId: ['0004']
    },
]

export default loginsData;