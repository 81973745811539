import React from 'react'

import { FacebookFilled, InstagramFilled, LinkedinFilled } from '@ant-design/icons'

import './footer.scss'
import { useEffect } from 'react';

const Footer = () => {
    useEffect(() => {
        if (window.location.hash === '#about-us') {
            const destinationElement = document.getElementById('about-us');
            if (destinationElement) {
                destinationElement.scrollIntoView();
            }
        }
    }, []);

    return (
        <div className='footer'>
            <div className='footer__container'>
                <div className='footer-logos'>
                    <a className='footer-logo' href='https://stemup.lk/' target='_blank' rel="noopener noreferrer"
                    >
                        <h4>
                            An initiative of
                        </h4>
                        <div className='footer-logo__img'>
                            <img src={require('../Assets/stemup.png')} alt='logo' />
                        </div>
                    </a>

                    <diva className='footer-logo-container'>
                        <h4>
                            In partnership with
                        </h4>
                        <div className='footer-logo__imgs'>
                            <a className='footer-logo__img' href='https://www.raspberrypi.org/' target='_blank' rel="noopener noreferrer">
                                <img src={require('../Assets/rapsberry.png')} alt='logo' />
                            </a>
                            <h4>
                                &
                            </h4>
                            <a className='footer-logo__img' href='https://www.mobitel.lk/' target='_blank' rel="noopener noreferrer">
                                <img src={require('../Assets/slt.png')} alt='logo' />
                            </a>
                        </div>
                    </diva>
                </div>

                <div className='footer-links'>
                    <h3>Quick Links</h3>
                    <ul>
                        <li>
                            <a href='/'>Home</a>
                        </li>
                        <li>
                            <a href='/programs'>Our Programs</a>
                        </li>
                        <li>
                            <a href='/privacy-policy'>
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a href='https://static.raspberrypi.org/files/safeguarding/Raspberry-Pi-Foundation-safeguarding-policy.pdf' target='_blank' rel='noopener noreferrer'>
                                Safe Guarding Policy
                            </a>
                        </li>
                    </ul>
                </div>

                <div className='footer-social'>
                    <h3>Follow Us</h3>
                    <div className='footer-social__icons'>
                        <a href='https://web.facebook.com/stemuplk' target='_blank' rel='noopener noreferrer'>
                            <FacebookFilled />
                        </a>

                        <a href='https://www.instagram.com/stemuplk/' target='_blank' rel='noopener noreferrer'>
                            <InstagramFilled />
                        </a>

                        <a href='https://www.linkedin.com/company/stemup-foundation/' target='_blank' rel='noopener noreferrer'>
                            <LinkedinFilled />
                        </a>
                    </div>
                </div>
            </div>

            <div className='footer-terms'>
                <p>
                    © 2024 STEMUP Educational Foundation. All Rights Reserved
                </p>

                <p>
                    Designed & Developed by <a href='https://www.isuruariyarathna.me/' target='_blank' rel='noopener noreferrer'>Isuru Ariyarathna</a>
                </p>
            </div>
        </div>
    )
}

export default Footer