import React, { useEffect, useState } from 'react'

import { programs } from '../../../Files/programs'

import './styles.scss'
import { Select } from 'antd'

const months = ['All', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September']

const Training = () => {
    const [programsFiltered, setProgramsFiltered] = useState([])

    useEffect(() => {
        setProgramsFiltered(programs)
    }, [])

    const monthWithYear = (month) => {
        const monthsBeforeOctober = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'];
        const year = monthsBeforeOctober.includes(month) ? '2025' : '2024';
        return `${month} ${year}`;
    };

    return (
        <div className='training'>
            <div className='header'>
                <h1>Training</h1>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a month"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                        if (value === 'All') {
                            setProgramsFiltered(programs)
                        } else {
                            setProgramsFiltered(programs.filter(program => program.month === value))
                        }
                    }}
                >
                    {months.map((month, index) => (
                        <Select.Option key={index} value={month}>
                            {
                                month === 'All' ? month : monthWithYear(month)
                            }
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div className='trins'>
                {programsFiltered.map((program, index) => (
                    <div key={index} className='program'>
                        <h2>{program.title}</h2>
                        <h3>Month: {program.month && monthWithYear(program.month)}</h3>
                        <div className='description'>
                            <p><strong>ID: </strong> {program.id}</p>
                            <p><strong>Session Date: </strong>{program.sessionDate}</p>
                        </div>
                        <div className='contents'>
                            <div className='sinhala'>
                                <h3>Sinhala</h3>
                                {program.videoSinhala !== "" ?
                                    <iframe
                                        width="400"
                                        height="220"
                                        src={program.videoSinhala}
                                        frameborder="0"
                                        allowfullscreen
                                        title='video'
                                    ></iframe>
                                    :
                                    <div className='vidna'>
                                        <p>
                                            Video Not Available
                                        </p>
                                    </div>
                                }

                                <div className='download'>
                                    {program.contentSinhala !== "" ?
                                        <a href={program.contentSinhala} download target='_blank' rel='noreferrer'>
                                            Download content
                                        </a>
                                        :
                                        <p className='notava'>
                                            Content Note Available
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className='english'>
                                <h3>English</h3>
                                {program.videoTamil !== "" ?
                                    <iframe width="400" height="220" src={program.videoTamil} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    :
                                    <div className='vidna'>
                                        <p>
                                            Video Not Available
                                        </p>
                                    </div>
                                }

                                <div className='download'>
                                    {program.contentTamil !== "" ?
                                        <a href={program.contentTamil} download target='_blank' rel='noreferrer'>
                                            Download content
                                        </a>
                                        :
                                        <p className='notava'>
                                            Content Note Available
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Training