export const codeclubs = [
    {
        id: '0001',
        name: 'Colombo Public Library',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.815850220447!2d79.85649251532593!3d6.912609020433907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2596ec416915f%3A0x665df8723d642688!2sColombo%20Public%20Library!5e0!3m2!1sen!2slk!4v1675015472879!5m2!1sen!2slk',
        district: 'Colombo',
        limit: 40
    },
    {
        id: '0002',
        name: 'Doluwa Public Library',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11100.285632491332!2d80.60556498133299!3d7.180295936269036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae36fc190f3dde5%3A0x95fee532d10d8daf!2sDoluwa%20Public%20Library!5e0!3m2!1sen!2slk!4v1680797878181!5m2!1sen!2slk',
        district: 'Kandy',
        limit: 40
    },
    {
        id: '0003',
        name: 'Hanguranketha Public Library',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.787476764276!2d80.59388261525649!3d7.265010316113068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae369ad67ae5d53%3A0x17763a919ee92320!2sSri%20Lanka%20Telecom%20Training%20Centre%2C%20Kandy!5e0!3m2!1sen!2slk!4v1680798001353!5m2!1sen!2slk',
        district: 'Nuwaraeliya',
        limit: 40
    },
    {
        id: '0004',
        name: 'Kirimatiyana Public Library',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d723324.3615334416!2d80.0178344170709!3d7.831531084217991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2c2f0301e1bbd%3A0x8bab9753f979a4b7!2zS2lyaW1hdGl5YW5hIFB1YmxpYyBMaWJyYXJ5IOC2muC3kuC2u-C3kuC2uOC3kOC2p-C3kuC2uuC3j-C2sSDgtrTgt5zgtq_gt5Qg4La04LeU4LeD4LeK4Lat4Laa4LeP4La94La6!5e0!3m2!1sen!2slk!4v1680798664735!5m2!1sen!2slk',
        district: 'Colombo',
        limit: 40
    },
    {
        id: '0005',
        name: 'Batticaloa Public Library',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d67800.60124632836!2d81.67305358134772!3d7.708531844201497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afacd5056dc1a7f%3A0x11979bb4f51a3e9a!2sPublic%20Library%20-%20Batticaloa!5e0!3m2!1sen!2slk!4v1680798756875!5m2!1sen!2slk',
        district: 'Batticaloa',
        limit: 40
    },
    {
        id: '0006',
        name: 'SLT Training Center, Welisara',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.9123082020114!2d79.89805731532608!3d7.01959361914327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2f7991e5d99cd%3A0x92464ead6e8776d0!2sSri%20Lanka%20Telecom%20Training%20Centre!5e0!3m2!1sen!2slk!4v1681179745688!5m2!1sen!2slk',
        district: 'Gampaha',
        limit: 40
    },
    {
        id: '0007',
        name: 'SLT Training Center, Peradeniya',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.787476764276!2d80.59388261525649!3d7.265010316113068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae369ad67ae5d53%3A0x17763a919ee92320!2sSri%20Lanka%20Telecom%20Training%20Centre%2C%20Kandy!5e0!3m2!1sen!2slk!4v1680798001353!5m2!1sen!2slk',
        district: 'Kandy',
        limit: 40
    },
    {
        id: '0008',
        name: 'SLT Training Center, Moratuwa',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.013187412457!2d79.89361141532586!3d6.76824542214606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae245a1ec4353b7%3A0xf69687f96b98e210!2sTelecom%20Training%20Centre%20Moratuwa!5e0!3m2!1sen!2slk!4v1681179348760!5m2!1sen!2slk',
        district: 'Colombo',
        limit: 40
    },
    {
        id: '0009',
        name: 'Mathara Public Library',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31746.459200389854!2d80.52669176321437!3d5.952314668057163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae138d436891799%3A0xf22c69a3ba8c8388!2zS3VtYXJhdGh1bmdhIE11bmlkYXNhIFB1YmxpYyBMaWJyYXJ5IC0gTWF0YXJhIHwg4Laa4LeU4La44LeP4La74Lat4LeU4LaC4LacIOC2uOC3lOC2seC3kuC2r-C3j-C3gyDgtrjgt4TgtqLgtrEg4La04LeU4LeD4LeK4Lat4Laa4LeP4La94La6IC0g4La44LeP4Lat4La7!5e0!3m2!1sen!2slk!4v1728221266635!5m2!1sen!2slk',
        district: 'Mathara',
        limit: 40
    },
    {
        id: '0010',
        name: 'Galle Public Library',
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253936.43137402492!2d79.91244579453125!3d6.02808960000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae173a43b199b7b%3A0xa3d3d31f49af2d95!2sGalle%20Library!5e0!3m2!1sen!2slk!4v1728221320468!5m2!1sen!2slk",
        district: 'Galle',
        limit: 40
    },
    {
        id: '0011',
        name: 'Kurunegala Public Library',
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3955.779594413985!2d80.3603812851099!3d7.489571373242575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae33a1dfcde28af%3A0xc14a9fa31b28fd10!2sKurunegala%20Public%20Library!5e0!3m2!1sen!2slk!4v1728221384358!5m2!1sen!2slk",
        district: 'Kurunegala',
        limit: 40
    },
    {
        id: '0012',
        name: 'Nochchiyagama Public Library',
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3948.2013632573185!2d80.38677647590748!3d8.282748191751734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afcf57f491ff149%3A0x56b8513f9aa167d6!2zUHVibGljIGxpYnJhcnkgLSDgtrjgt4TgtqLgtrEg4La04LeU4LeD4LeK4Lat4Laa4LeP4La94La6!5e0!3m2!1sen!2slk!4v1728221419688!5m2!1sen!2slk",
        district: 'Anuradhapura',
        limit: 25
    },
    {
        id: '0013',
        name: 'Kuliyapitiya Public Library',
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3955.9506142095815!2d80.03872147590066!3d7.470706092540968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2d9ba116c81b9%3A0x186a3c089758c3c!2sLibrary!5e0!3m2!1sen!2slk!4v1728221471888!5m2!1sen!2slk",
        district: 'Kurunegala',
        limit: 40
    },
    {
        id: '0014',
        name: 'Badulla Public Library',
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63366.196487091875!2d81.02291218339663!3d6.96355806738641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae461e21faa0559%3A0x86c909d6a988285!2z4La24Lav4LeU4La94LeK4La9IOC2tOC3iuKAjeC2u-C3j-C2r-C3muC3geC3k-C2uiDgt4Pgtrfgt48g4La04LeU4LeD4LeK4Lat4Laa4LeP4La94La6IFByYWRlc2hpeWEgU2FiYWggTGlicmFyeQ!5e0!3m2!1sen!2slk!4v1728221523265!5m2!1sen!2slk",
        district: 'Badulla',
        limit: 40
    },
    {
        id: '0015',
        name: 'Rathnapura Public Library',
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31701.154454750787!2d80.38219321353368!3d6.690903471480136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae3bf0c56ac3dfd%3A0xb74dbbfdff6b738e!2zUHVibGljIExpYnJhcnkg4La44LeE4Lai4LaxIOC2tOC3lOC3g-C3iuC2reC2muC3j-C2veC2ug!5e0!3m2!1sen!2slk!4v1728221556545!5m2!1sen!2slk",
        district: 'Rathnapura',
        limit: 40
    },
    {
        id: '0016',
        name: 'Eravur Public Library',
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d461169.9372153524!2d81.19757178336314!3d7.680721166430991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afad3f4651a8b6b%3A0xd859fa8e0f69b9f!2zUHVibGljIExpYnJhcnkgLSBFcmF2dXIgfCDgtrjgt4TgtqLgtrEg4La04LeU4LeD4LeK4Lat4Laa4LeP4La94La6IC0g4LaR4La74LeP4LeA4LeW4La74LeK!5e0!3m2!1sen!2slk!4v1728221607773!5m2!1sen!2slk",
        district: 'Batticaloa',
        limit: 40
    },
    {
        id: '0017',
        name: 'Ruwanwella Public Library',
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15838.500443927442!2d80.24515176217096!3d7.053261237184052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae30636770265dd%3A0x6c47156f005ab8b4!2sPublic%20Library%20Ruwanwella!5e0!3m2!1sen!2slk!4v1728221650096!5m2!1sen!2slk",
        district: 'Kegalle',
        limit: 40
    },
    {
        id: '0018',
        name: 'Galewela Public Library',
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.283372915532!2d80.56950917590298!3d7.759741892259386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afcb37dbe6ef16d%3A0xaed26027b2f72e78!2sGalewela%20Public%20Library!5e0!3m2!1sen!2slk!4v1728221697080!5m2!1sen!2slk",
        district: 'Matale',
        limit: 40
    },
    {
        id: '0019',
        name: 'Hambanthota Municipal Council Library',
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.720941247402!2d81.12176338504538!3d6.168110677882465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae6a3006a7cac4d%3A0xf9efed2a128fd64f!2sPublic%20library%20municipal%20council%20-%20Hambanthota!5e0!3m2!1sen!2slk!4v1728221744029!5m2!1sen!2slk",
        district: 'Hambanthota',
        limit: 40
    },
    {
        id: '0020',
        name: 'Panadura Urban Council Library',
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.443589395426!2d79.90453717589503!3d6.715596993280274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae246101df00001%3A0x11f913cd39e52aac!2sPublic%20Library%20Panadura!5e0!3m2!1sen!2slk!4v1728221816664!5m2!1sen!2slk",
        district: 'Kaluthara',
        limit: 40
    },
]
